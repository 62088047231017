import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import JobDetailsUserBox from '../Job/JobDetailsUserBox';
import UserSearchField from '../Job/UserSearchField';
import history from '../../../history';
import { createLoadingSelector } from '../../../apis/selectors';
import 'react-quill/dist/quill.snow.css';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { addChildOffice, deleteChildOffice } from '../../../actions/userActions';
import { Colors } from '../../../themes/colors';
import { TabViewContainer } from './TabViewContainer';

class ChildOffices extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.state = {
      dentist: null,
    };
  }

  renderTitle() {
    return (
      <div
        style={{
          width: '100%',
          borderBottom: '1px solid #efefef',
          padding: '5px 10px',
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: 'lightblue',
        }}
      >
        <p className="card-text" style={{ fontWeight: '600', width: '30%', textAlign: 'center' }}>
          Office Name
        </p>
        <p className="card-text" style={{ fontWeight: '600', textAlign: 'center', width: '30%' }}>
          Address
        </p>
        <p className="card-text" style={{ fontWeight: '600', textAlign: 'center', width: '20%' }}>
          Worker Type
        </p>
        <p className="card-text" style={{ fontWeight: '600' }}>
          Remove
        </p>
      </div>
    );
  }

  addDentist = async () => {
    const { currentUser, addChildOffice } = this.props;
    const { dentist } = this.state;

    addChildOffice(currentUser._id, dentist._id);
    this.setState({ dentist: undefined });
  };

  renderDentistSearch() {
    if (this.state.dentist) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: 200,
          }}
        >
          <p
            className="card-text"
            style={{ fontWeight: '600', width: '100%', textAlign: 'center' }}
          >
            Add Child Office:
          </p>
          <JobDetailsUserBox
            user={this.state.dentist}
            onCancel={(_) => this.setState({ dentist: undefined })}
          />
          <button
            onClick={(_) => this.addDentist()}
            className="btn btn-success"
            style={{ marginTop: 12 }}
          >
            Add Dentist
          </button>
        </div>
      );
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'start',
          width: '20%',
        }}
      >
        <p className="card-text" style={{ fontWeight: '600', width: '100%', textAlign: 'center' }}>
          Add Child Office:
        </p>
        <UserSearchField
          style={{ flex: 1 }}
          user_type="DNT"
          placeholder="Type a Dentist Name"
          onSelectUser={(u) => this.setState({ dentist: u })}
          except={[this.props.currentUser._id]}
        />
      </div>
    );
  }

  dsoOffices = () => {
    const { childOffices, isChildUpdateOfficeLoading } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '90%',
        }}
      >
        {this.renderDentistSearch()}
        {childOffices?.length !== 0 ? (
          <div className="card" style={{ width: '100%', height: '100%', marginLeft: 20 }}>
            {this.renderTitle()}
            {childOffices?.map((office) => this.renderDnt(office))}
            {isChildUpdateOfficeLoading && (
              <Spinner
                animation="border"
                role="status"
                variant="info"
                style={{
                  height: 30,
                  width: 30,
                  marginLeft: 20,
                  marginTop: 10,
                  marginBottom: 10,
                  alignSelf: 'center',
                }}
              />
            )}
          </div>
        ) : (
          'There are no child offices'
        )}
      </div>
    );
  };

  handleDeleteChildOffice = async (dnt) => {
    const { currentUser, deleteChildOffice } = this.props;
    deleteChildOffice(currentUser._id, dnt.id);
  };

  goToDntProfile = (dnt) => {
    history.push(`/user/dentist/${dnt.id}`, {
      user_id: dnt.id,
      profile: 'DENTIST',
    });
  };

  renderDnt = (dnt) => (
    <div
      key={dnt.id}
      style={{
        width: '100%',
        borderBottom: '1px solid #efefef',
        padding: '5px 10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <p
        className="card-text"
        onClick={() => this.goToDntProfile(dnt)}
        style={{
          width: '30%',
          textAlign: 'center',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
      >
        {dnt.office_name}
      </p>
      <p className="card-text" style={{ width: '30%', textAlign: 'center' }}>
        {dnt?.address?.[0]?.formatted_address || '-'}
      </p>
      <p
        className="card-text"
        style={{
          width: '30%',
          textAlign: 'center',
          textTransform: 'capitalize',
          fontWeight: 'bold',
        }}
      >
        {dnt?.workerClassification?.classificationType || '-'}
      </p>
      <img
        src={require('../../../images/close-cross.png')}
        alt="remove"
        style={{ width: '20px', cursor: 'pointer' }}
        onClick={() => this.handleDeleteChildOffice(dnt)}
      />
    </div>
  );

  render() {
    return (
      <TabViewContainer
        style={{
          alignItems: 'center',
        }}
        title="Child Offices"
      >
        {this.dsoOffices()}
      </TabViewContainer>
    );
  }
}

const actions = ['DELETE_CHILD_OFFICE', 'ADD_CHILD_OFFICE'];
const loadingSelector = createLoadingSelector(actions);

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
  childOffices: state.users.childOffices,
  isChildUpdateOfficeLoading: loadingSelector(state),
});

export default connect(mapStateToProps, {
  addChildOffice,
  deleteChildOffice,
})(ChildOffices);
