import React from 'react';
import EditsRenderData from './EditsRenderData';
import { TabViewContainer } from '../../User/TabViewContainer';
import { Colors } from '../../../../themes/colors';

export default ({ editList, isUserEdits }) => (
  <TabViewContainer
    TitleTrailing={
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flex: 1,
          justifyContent: 'end',
        }}
      >
        <div
          style={{
            backgroundColor: '#b4b7ed',
            height: 40,
            width: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 20,
          }}
        >
          <h3
            className="card-title"
            style={{
              color: Colors.white,
              margin: 0,
              padding: 0,
            }}
          >
            {editList.length}
          </h3>
        </div>
      </div>
    }
    style={{
      alignItems: 'center',
      width: '80%',
    }}
    title="Edits"
    variant="card"
  >
    <div
      style={{
        display: 'flex',
        borderBottom: '1px solid #ededed',
        flex: 1,
        marginLeft: 20,
        marginRight: 20,
        width: '100%',
      }}
    >
      <div style={{ flex: 5 }}>
        <strong>Date</strong>
      </div>
      <div style={{ flex: 3 }}>
        <strong>Author</strong>
      </div>
      <div style={{ flex: 3 }}>
        <strong>Type</strong>
      </div>
      <div style={{ flex: 3 }}>
        <strong>Old Value</strong>
      </div>
      <div style={{ flex: 3 }}>
        <strong>New Value</strong>
      </div>
    </div>
    <EditsRenderData editList={editList} isUserEdits={isUserEdits} />
  </TabViewContainer>
);
