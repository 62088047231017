import moment from 'moment';
import { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { useDispatch, useSelector } from 'react-redux';
import { clearDataRange, reloadJobs } from '../../../actions/jobsAction';
import history from '../../../history';
import CloseCross from '../../../images/close-cross.png';
import Styles from '../../../themes/style.module.scss';
import MultiSelect from '../../commonComponents/MultiSelect';
import { states } from '../../commonComponents/StatePicker';
import debounceHook from '../../commonComponents/debounceHook';
import { DsoNameFilter } from '../UsersList/components/DsoNameFilter';
import { DsoRAMFilter } from '../UsersList/components/DsoRamFilter';
import { useAvailableSpecialties } from '../../../hooks/useAvailableSpecialties';
import { EducationUtils } from '../../../utils/EducationUtils';

export const CustomHeader = ({ filter, handleFilterChange, payOutOnly }) => {
  const dispatch = useDispatch();
  const searchParams = useSelector((state) => state.jobs.searchParams);
  const allProfessions = useSelector((state) => state.jobs.allProfessions);
  const requestedEducations = useSelector((state) => state.users.requestedEducations);
  const ramUsers = useSelector((state) => state.users.ramUsers);
  const dsoManagers = useSelector((state) => state.users.dsoManagers);
  const totalFiltered = useSelector((state) => state.jobs.totalFiltered);
  const totalJobs = useSelector((state) => state.jobs.total);
  const [showDateRange, setShowDateRange] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const availableRdhSpecialties = useAvailableSpecialties(requestedEducations?.RDH);
  const availableDaSpecialties = useAvailableSpecialties(requestedEducations?.DA);

  const [searchText, setSearchText] = useState(filter?.searchText || '');
  const debouncedSearchText = debounceHook(searchText, 1000);

  const goToCreateJob = () => {
    history.push('/jobcreate');
  };

  const handleOnChange = (type, value) => {
    handleFilterChange(type, value);
  };

  const handleShowDateRangeChange = () => {
    setShowDateRange(!showDateRange);
  };

  const handleChangeDateRange = (value) => {
    setDateRange(value.selection);
  };

  const clearDateRangeSearch = () => {
    setDateRange({
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    });

    dispatch(clearDataRange());

    handleFilterChange('dateRange', {
      startDate: null,
      endDate: null,
      key: 'selection',
    });

    dispatch(reloadJobs());
  };

  const stateOptions = [
    ...states.map((el) => ({
      label: el.name,
      value: el.abbreviation,
    })),
  ];

  const ramUsersOptions = [
    { label: 'Unassigned', value: 'unassigned' },
    ...ramUsers.map((el) => ({
      label: [el.first_name, el.last_name].join(' '),
      value: el.id,
    })),
  ];

  const dsoManagersOptions = [
    { label: 'Unassigned', value: 'unassigned' },
    ...dsoManagers.map((el) => ({
      label: [el.first_name, el.last_name].join(' '),
      value: el.id,
    })),
  ];

  const subProfession = searchParams ? searchParams.subProfession : 'all';

  const cardStyle = showDateRange
    ? {
      visibility: 'visible',
      transition: 'height 0.5s, opacity 0.7s linear',
      height: 450,
      zIndex: 10,
      backgroundColor: 'white',
      opacity: 1,
      width: '50%',
      marginTop: 16,
    }
    : {
      visibility: 'hidden',
      height: 0,
      opacity: 0,
      width: '50%',
      marginTop: 16,
      transition: 'height 0.5s, opacity 0.3s linear, visibility 0.3s',
    };

  useEffect(() => {
    if (filter.searchText !== searchText) {
      handleFilterChange('searchText', searchText);
    }
  }, [debouncedSearchText, filter.searchText]);

  return (
    <div style={{ marginTop: 24 }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: 16,
            width: '100%',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p>Search:</p>
            <input
              style={{
                paddingLeft: '8px',
                fontFamily: 'Nunito-Regular',
                width: 200,
                height: 32,
              }}
              placeholder="Search"
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
              }}
            />
          </div>
          {!payOutOnly ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: 200,
              }}
            >
              <p>Status:</p>

              <select
                className="custom-select custom-select-lg"
                style={{ height: 32 }}
                value={filter.jobStatus}
                onChange={(val) => handleOnChange('jobStatus', val.target.value)}
              >
                <option value="all">All</option>
                <option value="active">Active</option>
                <option value="pending">Pending</option>
                <option value="open">Open</option>
                <option value="counter">Counter</option>
                <option value="filled">Filled</option>
                <option value="pay-out">Pay Out</option>
                <option value="cancelled">Cancelled</option>
                <option value="cancelled-hyg">Cancelled by HYG</option>
                <option value="cancelled-dnt">Cancelled by DNT</option>
                <option value="expired">Expired</option>
                <option value="complete">Complete</option>
                <option value="refill">Refill</option>
                <option value="adjusted-required">Adjusted required</option>
                <option value="pay-out-disabled">Payout Disabled</option>
                <option value="refund-disabled">Refund Disabled</option>
                <option value="pending-counter">Pending & Counter</option>
              </select>
            </div>
          ) : null}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: 200,
            }}
          >
            <p>State:</p>

            <MultiSelect
              className="custom-select custom-select-lg"
              style={{ height: 32 }}
              value={
                filter.state && filter.state !== 'all'
                  ? filter.state.split(',')
                  : stateOptions.map((el) => el.value)
              }
              options={stateOptions}
              onChange={(values) => {
                // if all options are selected, then we don't need to send the filter to backend
                handleOnChange(
                  'state',
                  values.length === stateOptions.length ? null : values.join(','),
                );
              }}
              placeholder="All"
              showAllButton
              autoApply={false}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: 200,
            }}
          >
            <p>Profession:</p>

            <select
              className="custom-select custom-select-lg"
              style={{ height: 32 }}
              value={filter.profession}
              onChange={(val) => {
                handleOnChange('profession', val.target.value !== 'all' ? val.target.value : '');
                handleOnChange('subProfession', subProfession);
              }}
            >
              <option value="all">All</option>
              {allProfessions.map((el) => (
                <option value={el.code}>{el.name}</option>
              ))}
            </select>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: 200,
            }}
          >
            <p>RAM:</p>

            <MultiSelect
              className="custom-select custom-select-lg"
              style={{ height: 32 }}
              value={
                filter.ram && filter.ram !== 'all'
                  ? filter.ram.split(',')
                  : ramUsersOptions.map((el) => el.value)
              }
              options={ramUsersOptions}
              onChange={(values) => {
                // if all options are selected, then we don't need to send the filter to backend
                handleOnChange(
                  'ram',
                  values.length === ramUsersOptions.length ? null : values.join(','),
                );
              }}
              placeholder="All"
              showAllButton
              autoApply={false}
            />
          </div>

          <DsoNameFilter
            filter={filter}
            handleFilterChange={handleFilterChange}
            customStyles={{
              width: 200,
            }}
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: 200,
            }}
          >
            <p>DSO Manager:</p>

            <MultiSelect
              className="custom-select custom-select-lg"
              style={{ height: 32 }}
              optionsStyle={{ width: 180 }}
              value={
                filter.dsoManager && filter.dsoManager !== 'all'
                  ? filter.dsoManager.split(',')
                  : dsoManagersOptions.map((el) => el.value)
              }
              options={dsoManagersOptions}
              onChange={(values) => {
                // if all options are selected, then we don't need to send the filter to backend
                handleOnChange(
                  'dsoManager',
                  values.length === dsoManagersOptions.length ? null : values.join(','),
                );
              }}
              placeholder="All"
              showAllButton
              autoApply={false}
            />
          </div>

          <DsoRAMFilter
            filter={filter}
            handleFilterChange={handleFilterChange}
            customStyles={{
              width: 200,
            }}
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: 200,
            }}
          >
            <p>RDH Skills:</p>

            <select
              className="custom-select custom-select-lg"
              style={{ height: 32 }}
              value={filter.specialtyRdh}
              onChange={(val) =>
                handleOnChange('specialtyRdh', val.target.value !== 'all' ? val.target.value : '')
              }
            >
              <option value="all">All</option>
              {availableRdhSpecialties.map((specialty) => (
                <option value={specialty}>{EducationUtils.removeSpecialtyPrefix(specialty)}</option>
              ))}
            </select>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: 200,
            }}
          >
            <p>DA Skills:</p>

            <select
              className="custom-select custom-select-lg"
              style={{ height: 32 }}
              value={filter.specialtyDa}
              onChange={(val) =>
                handleOnChange('specialtyDa', val.target.value !== 'all' ? val.target.value : '')
              }
            >
              <option value="all">All</option>
              {availableDaSpecialties.map((specialty) => (
                <option value={specialty}>{EducationUtils.removeSpecialtyPrefix(specialty)}</option>
              ))}
            </select>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: 200,
            }}
          >
            <p>Worker Type:</p>

            <select
              className="custom-select custom-select-lg"
              style={{ height: 32 }}
              value={filter.workerClassification || 'all'}
              onChange={(val) => handleOnChange('workerClassification', val.target.value)}
            >
              <option className="dropdown-item" value="all">
                All
              </option>
              <option className="dropdown-item" value="employee">
                Employee
              </option>
              <option className="dropdown-item" value="contractor">
                Contractor
              </option>
            </select>
          </div>

          <button
            style={{
              width: 200,
              height: 32,
              justifySelf: 'flex-end',
              alignSelf: 'flex-end',
            }}
            className="btn btn-success"
            onClick={handleShowDateRangeChange}
            type="button"
          >
            Search by Date Range
          </button>
        </div>
      </div>

      {searchParams.startDate ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginLeft: 10,
            marginTop: 20,
            justifyContent: 'flex-end',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <p style={{ marginBottom: 0 }}>Selected Date Range:</p>
            <p style={{ width: 200 }}>
              {`${moment(searchParams.startDate).format('MM-DD-YYYY') || ''} - ${
                moment(searchParams.endDate).format('MM-DD-YYYY') || ''
              }`}
            </p>
          </div>
          <img
            src={CloseCross}
            style={{
              width: '20px',
              height: '20px',
              marginLeft: 5,
              marginTop: 15,
              cursor: 'pointer',
            }}
            onClick={clearDateRangeSearch}
            alt="close"
          />
        </div>
      ) : null}

      <div className="card" style={cardStyle}>
        <DateRangePicker
          style={{ marginTop: 30 }}
          ranges={[dateRange]}
          onChange={handleChangeDateRange}
        />
        <button
          style={{ width: 150, margin: 'auto' }}
          className="btn btn-success mt-2 mb-2"
          onClick={() => {
            handleFilterChange('dateRange', dateRange);
            handleShowDateRangeChange();
          }}
          type="button"
        >
          Search
        </button>
      </div>

      <div
        style={{
          marginTop: 24,
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 48,
        }}
      >
        <button onClick={goToCreateJob} className={Styles.create_job_btn} type="button">
          Post a Job
        </button>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h4
            className={Styles.h4}
            style={{
              fontSize: '20px',
              fontWeight: 'bold',
              textAlign: 'right',
            }}
          >
            Total |{new Intl.NumberFormat().format(totalJobs)}
          </h4>
          <h4
            className={Styles.h4}
            style={{
              fontSize: '20px',
              fontWeight: 'bold',
              textAlign: 'right',
            }}
          >
            Total {filter.jobStatus} | {new Intl.NumberFormat().format(totalFiltered)}
          </h4>
        </div>
      </div>
    </div>
  );
};
