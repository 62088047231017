import {
  APPROVE_USER_BY_SMS,
  FETCH_DENTIST_REQUEST,
  FETCH_DSO_REQUEST,
  FETCH_DENTIST_SUCCESS,
  FETCH_DSO_SUCCESS,
  FETCH_HYGIENIST_REQUEST,
  FETCH_HYGIENIST_SUCCESS,
  FETCH_CURRENT_USER_SUCCESS,
  UPDATE_CURRENT_USER,
  ADD_NEW_ADDRESS_SUCCESS,
  PATCH_HYGIENIST_NOTIFICATION,
  LOADING_NOTIFICATION_SETTINGS_UPDATE,
  UPDATE_USER_AVATAR_SUCCESS,
  FETCH_USER_JOBS_SUCCESS,
  FETCH_UNFINISHED_USERS_SUCCESS,
  DELETE_USER_ADDRESS_SUCCESS,
  APPROVE_USER_BY_EMAIL,
  DELETE_USER_SUCCESS,
  FETCH_UNFINISHED_USERS_REQUEST,
  BLOCK_HYG_SUCCESS,
  GET_USERS_SUCCESS,
  UNBLOCK_HYG_SUCCESS,
  UPDATE_USER_LICENSE_SUCCESS,
  ADD_LICENSE_SUCCESS,
  DELETE_LICENSE_SUCCESS,
  GET_USER_PROFESSIONS_SUCCESS,
  GET_ADMIN_USER_SUCCESS,
  FETCH_USER_JOBS_REQUEST,
  REPORT_ACTIONS,
  UPDATE_USER_AVAILABILITY_SUCCESS,
  GET_MANAGERS_LIST_SUCCESS,
  FETCH_USER_NOTES_SUCCESS,
  ADD_USER_NOTE_SUCCESS,
  FETCH_SHIFT_NOTES_SUCCESS,
  ADD_SHIFT_NOTE_SUCCESS,
  CREATE_STRIPE_ACCOUNT_SUCCESS,
  FETCH_CHILD_OFFICES_SUCCESS,
  ADD_CHILD_OFFICE_SUCCESS,
  DELETE_CHILD_OFFICE_SUCCESS,
  PP_JOB_ACTIONS,
  USER_ACTIONS,
  MAP_TYPES,
  FETCH_USER_OFFERS_SUCCESS,
  FETCH_USER_OFFERS_REQUEST,
  FETCH_USER_NOTIFICATION_SETTINGS_SUCCESS,
  UPDATE_USER_NOTIFICATION_SETTINGS_SUCCESS,
  FETCH_USER_FAVORITES_LIST_SUCCESS,
  DELETE_FAVORITE_USER_SUCCESS,
  ADD_FAVORITE_USER_SUCCESS,
  GET_DSO_NAMES_SUCCESS,
  UPDATE_USER_NOTIFICATION_SETTINGS_HYG_SUCCESS,
  FETCH_USER_NOTIFICATION_SETTINGS_HYG_SUCCESS,
  GET_CANCELLATION_POLICIES_SUCCESS,
  FETCH_PARTNERSHIP_ORGANIZATIONS_SUCCESS,
  GET_ALL_METADATA_SUCCESS,
  UPDATE_CURRENT_USER_PAYMENT_SUCCESS,
  HIDE_EXISTING_LICENSE_POPUP,
  UPDATE_RAM_VERIFIED_STATUS_SUCCESS,
  GET_REFERRAL_TYPES_SUCCESS,
  UPDATE_USER_REFERRAL_TYPE_SUCCESS,
  EDUCATION_ACTION,
  FETCH_USER_WORKER_CLASSIFICATION_SUCCESS,
  JOB_ACTION,
  SAVE_USER_WORKER_CLASSIFICATION_SUCCESS,
  UPDATE_NATIONAL_PROVIDER_IDENTIFIER_SUCCESS,
  FETCH_CURRENT_USER_STRIPE_ID,
  FETCH_CURRENT_USER_REQUEST,
  FETCH_OFFICES_WITH_FAVORITED_PROFESSIONAL_SUCCESS,
  FETCH_OFFICES_WITH_FAVORITED_PROFESSIONAL_REQUEST,
  FETCH_USER_HIGHLY_RATED_PROFESSIONALS_LIST_SUCCESS,
  FETCH_USER_HIGHLY_RATED_PROFESSIONALS_LIST_REQUEST,
} from '../actions/actionTypes';

const defaultState = {
  adminUser: null,
  isPayoutUser: false,
  isPreApproveUser: false,
  usersHYG: [],
  receiptsPdfJobs: [],
  officeReceipts: {
    receipts: [],
    totalReceipts: 0,
    isActiveRequest: false,
    isFinalPage: false,
    receiptsPagination: {
      page: 1,
      limit: 15,
    },
  },
  usersDNT: [],
  usersDSO: [],
  unfinsihedUsers: [],
  unfinsihedUsersCount: 0,
  countHYG: 0,
  countDNT: 0,
  countDSO: 0,
  currentUser: null,
  surroundingUserList: [],
  // hyg state id
  newStateId: null,
  loadingNotificationSettingsUpdate: false,
  dentistSearchParams: null,
  dsoSearchParams: null,
  unfinishedSearchParams: null,
  userReport: '',
  reportType: '',
  hygienistSearchParams: null,
  searchParams: {
    page: 1,
    sortField: 'job_datetime',
    sortOrder: 'desc',
  },
  searchParamsPPJobs: {
    page: 1,
    limit: 15,
    sortField: 'createdAt',
    sortOrder: 'desc',
  },
  searchParamsOffers: {
    page: null,
    limit: null,
  },
  totalJobsCount: 0,
  totalOffersCount: 0,
  totalPPJobsCount: 0,
  userVerified: false,
  userJobs: [],
  userOffers: [],
  userPPJobs: [],
  userList: [],
  childOffices: [],
  totalReviews: 0,
  reviewReport: '',
  ramUsers: [],
  dsoManagers: [],
  dsoRAMManagers: [],
  dsoNames: [],
  referralTypes: [],
  cancellationPolicies: [],
  partnershipOrganizations: [],
  blockedForOffices: [],
  currentUserStripeId: null,
  reviews: {
    reviews: [],
    reviewsPagination: {
      page: 1,
      limit: 15,
    },
    totalReview: 0,
    isFinalPage: false,
    isActiveRequest: false,
  },
  officeInvoices: {
    invoices: [],
    totalInvoices: 0,
    totalUnpaidInvoices: {
      sum: 0,
      count: 0,
    },
    invoicesPagination: {
      page: 1,
      limit: 15,
      sort: 'date',
      order: 'desc',
    },
    isFinalPage: false,
    isActiveRequest: false,
  },
  userEducation: null,
  requestedEducations: {},
  allMetadata: null,
  metadata: {},
  totalNoShowCount: 0,
  totalCompletedCount: 0,
  totalCancellationCount: 0,
  totalLateCancellationCount: 0,
  totalNoShowByDayCount: 0,
  totalCancellationByDayCount: 0,
  totalLateCancellationByDayCount: 0,
  attendancePercentage: 0,
  averageRating: 0,
  showExistingLicensePopup: false,
  officesWithFavoritedProfessional: {
    page: 1,
    limit: 10,
    offices: [],
    total: 0,
    isActiveRequest: false,
    isFinal: false,
  },
  office: {
    highlyRatedProfessionals: {
      page: 1,
      limit: 15,
      data: [],
      isActiveRequest: false,
      isFinal: false,
    },
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
  case GET_ADMIN_USER_SUCCESS: {
    const adminUser = action.payload;
    const isPayoutUser = true; // process.env.REACT_APP_ADMIN_IDS.split(',').includes(adminUser.id);
    const isPreApproveUser = true; // process.env.REACT_APP_PREAPPROVE_IDS.split(',').includes(adminUser.id);

    return {
      ...state,
      adminUser,
      isPayoutUser,
      isPreApproveUser,
    };
  }
  case USER_ACTIONS.FETCH_USER_REVIEWS_REQUEST: {
    return {
      ...state,
      reviews: {
        ...state.reviews,
        reviewsPagination: action.payload,
        isActiveRequest: true,
      },
    };
  }
  case MAP_TYPES.FETCH_USERS_AROUND_SUCCESS: {
    return {
      ...state,
      surroundingUserList: action.payload,
    };
  }
  case USER_ACTIONS.FETCH_USER_REVIEWS_SUCCESS: {
    const newReviews = action.payload.response;
    const mergedReviews = [...state.reviews.reviews, ...newReviews];
    return {
      ...state,
      reviews: {
        ...state.reviews,
        isActiveRequest: false,
        reviews: action.payload.page === 1 ? newReviews : mergedReviews,
        totalReview: action.payload.total,
        isFinalPage:
            newReviews.length === 0 ||
            newReviews.length < state.reviews.reviewsPagination.limit ||
            newReviews.length === state.reviews.totalReview,
      },
    };
  }
  case FETCH_USER_JOBS_REQUEST:
    return {
      ...state,
      searchParams: action.payload,
    };
  case FETCH_USER_OFFERS_REQUEST:
    return {
      ...state,
      searchParamsOffers: action.payload,
    };
  case PP_JOB_ACTIONS.FETCH_USER_PP_JOBS_REQUEST:
    return {
      ...state,
      searchParamsPPJobs: action.payload,
    };

  case FETCH_CURRENT_USER_REQUEST: {
    return {
      ...state,
      currentUserStripeId: null,
    };
  }
  case FETCH_CURRENT_USER_STRIPE_ID:
    return {
      ...state,
      currentUserStripeId: action.payload,
    };
  case FETCH_CURRENT_USER_SUCCESS: {
    const { user } = action.payload;

    return {
      ...state,
      currentUser: user,
      newStateId: null,
      surroundingUserList: [],
    };
  }
  case FETCH_USER_JOBS_SUCCESS:
    return {
      ...state,
      userJobs: action.payload.jobs,
      totalJobsCount: action.payload.total,
    };
  case FETCH_USER_OFFERS_SUCCESS:
    return {
      ...state,
      userOffers: action.payload.offers,
      totalOffersCount: action.payload.total,
    };
  case PP_JOB_ACTIONS.FETCH_USER_PP_JOBS_SUCCESS:
    return {
      ...state,
      userPPJobs: action.payload.jobs,
      totalPPJobsCount: action.payload.total,
    };
  case FETCH_USER_NOTES_SUCCESS:
    return {
      ...state,
      currentUser: { ...state.currentUser, notes: action.payload },
    };
  case ADD_USER_NOTE_SUCCESS:
    return {
      ...state,
      currentUser: { ...state.currentUser, notes: [...state.currentUser.notes, action.payload] },
    };
  case FETCH_SHIFT_NOTES_SUCCESS:
    return {
      ...state,
      currentUser: { ...state.currentUser, notes: action.payload },
    };
  case ADD_SHIFT_NOTE_SUCCESS:
    return {
      ...state,
      currentUser: { ...state.currentUser, notes: [...state.currentUser.notes, action.payload] },
    };
  case REPORT_ACTIONS.REVIEW_REPORT_SUCCESS:
    return {
      ...state,
      reviewReport: action.payload,
    };
  case UPDATE_USER_NOTIFICATION_SETTINGS_SUCCESS:
  case FETCH_USER_NOTIFICATION_SETTINGS_SUCCESS:
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        notificationSettings: action.payload,
      },
    };
  case UPDATE_USER_NOTIFICATION_SETTINGS_HYG_SUCCESS:
  case FETCH_USER_NOTIFICATION_SETTINGS_HYG_SUCCESS:
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        notificationSettingsHYG: action.payload,
      },
    };
  case FETCH_USER_FAVORITES_LIST_SUCCESS:
  case ADD_FAVORITE_USER_SUCCESS:
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        favoritesList: action.payload,
      },
    };

  case FETCH_USER_HIGHLY_RATED_PROFESSIONALS_LIST_REQUEST: {
    return {
      ...state,
      office: {
        ...state.office,
        highlyRatedProfessionals: {
          ...state.office.highlyRatedProfessionals,
          page: action.payload.page,
          isActiveRequest: true,
        },
      },
    };
  }

  case FETCH_USER_HIGHLY_RATED_PROFESSIONALS_LIST_SUCCESS: {
    return {
      ...state,
      office: {
        ...state.office,
        highlyRatedProfessionals: {
          ...state.office.highlyRatedProfessionals,
          data:
              action.payload.page === 1
                ? action.payload.response
                : [...state.office.highlyRatedProfessionals.data, ...action.payload.response],
          isFinal:
              action.payload.response.length === 0 ||
              action.payload.response.length < state.office.highlyRatedProfessionals.limit,
          isActiveRequest: false,
        },
      },
    };
  }

  case FETCH_OFFICES_WITH_FAVORITED_PROFESSIONAL_REQUEST:
    return {
      ...state,
      officesWithFavoritedProfessional: {
        ...state.officesWithFavoritedProfessional,
        page: action.payload.page,
        limit: action.payload.limit,
        isActiveRequest: true,
      },
    };
  case FETCH_OFFICES_WITH_FAVORITED_PROFESSIONAL_SUCCESS: {
    const mergedOffices = [
      ...state.officesWithFavoritedProfessional.offices,
      ...action.payload.offices,
    ];
    return {
      ...state,
      officesWithFavoritedProfessional: {
        offices: action.payload.page === 1 ? action.payload.offices : mergedOffices,
        page: action.payload.page,
        limit: action.payload.limit,
        total: action.payload.total,
        isFinal:
            action.payload.offices.length === 0 ||
            action.payload.offices.length < state.officesWithFavoritedProfessional.limit ||
            mergedOffices.length === action.payload.total,
        isActiveRequest: false,
      },
    };
  }
  case FETCH_USER_WORKER_CLASSIFICATION_SUCCESS:
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        workerClassificationInfo: action.payload,
      },
    };

  case SAVE_USER_WORKER_CLASSIFICATION_SUCCESS:
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        ...action.payload,
      },
    };

  case DELETE_FAVORITE_USER_SUCCESS:
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        favoritesList: state.currentUser.favoritesList?.filter(({ id }) => id !== action.payload),
      },
    };
  case UPDATE_CURRENT_USER:
  case UNBLOCK_HYG_SUCCESS:
  case BLOCK_HYG_SUCCESS:
  case UPDATE_USER_AVAILABILITY_SUCCESS:
  case UPDATE_CURRENT_USER_PAYMENT_SUCCESS:
    return {
      ...state,
      currentUser: action.payload,
      userList: [],
    };
  case UPDATE_RAM_VERIFIED_STATUS_SUCCESS:
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        office_info: {
          ...state.currentUser.office_info,
          ...action.payload,
        },
      },
    };
  case UPDATE_USER_REFERRAL_TYPE_SUCCESS:
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        defaultReferralType: action.payload,
      },
    };
  case GET_USERS_SUCCESS:
    return {
      ...state,
      userList: action.payload?.users || [],
    };
  case FETCH_CHILD_OFFICES_SUCCESS:
  case DELETE_CHILD_OFFICE_SUCCESS:
  case ADD_CHILD_OFFICE_SUCCESS:
    return {
      ...state,
      childOffices: action.payload || [],
    };
  case 'CLEAR_USERS_SUCCESS':
    return {
      ...state,
      userList: [],
    };
  case ADD_NEW_ADDRESS_SUCCESS: {
    const { user_type } = state.currentUser;
    const address =
        user_type === 'DNT' ? [action.payload] : [...state.currentUser.address, action.payload];

    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        address,
      },
    };
  }
  case UPDATE_USER_AVATAR_SUCCESS:
    return {
      ...state,
      isUploadingAvatar: false,
      currentUser: action.payload,
    };
  case FETCH_HYGIENIST_SUCCESS:
    return {
      ...state,
      usersHYG: action.payload.users,
      countHYG: action.payload.total,
    };
  case DELETE_USER_ADDRESS_SUCCESS: {
    const index = state.currentUser.address.findIndex((address) => address.id === action.payload);

    if (index === -1) {
      return state;
    }

    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        address: [
          ...state.currentUser.address.slice(0, index),
          ...state.currentUser.address.slice(index + 1),
        ],
      },
    };
  }
  case USER_ACTIONS.GET_OFFICES_BLOCKED_SUCCESS:
    return {
      ...state,
      blockedForOffices: action.payload,
    };

  case APPROVE_USER_BY_EMAIL:
  case DELETE_USER_SUCCESS:
  case APPROVE_USER_BY_SMS:
    return {
      ...state,
      message: action.payload.message,
    };
  case PATCH_HYGIENIST_NOTIFICATION:
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        notification_settings: action.payload,
      },
      loadingNotificationSettingsUpdate: false,
    };
  case FETCH_UNFINISHED_USERS_REQUEST:
    return {
      ...state,
      unfinishedSearchParams: action.payload,
      userReport: '',
      reportType: '',
    };
  case REPORT_ACTIONS.FETCH_USERS_REPORT_SUCCESS: {
    const { report, reportType } = action.payload;

    return {
      ...state,
      userReport: report,
      reportType,
    };
  }

  case FETCH_UNFINISHED_USERS_SUCCESS: {
    return {
      ...state,
      unfinsihedUsers: action.payload.users,
      unfinsihedUsersCount: action.payload.total,
    };
  }

  case FETCH_DENTIST_SUCCESS: {
    return {
      ...state,
      usersDNT: action.payload.users,
      countDNT: action.payload.total,
    };
  }
  case FETCH_DSO_SUCCESS:
    return {
      ...state,
      usersDSO: action.payload.users,
      countDSO: action.payload.total,
    };

  case LOADING_NOTIFICATION_SETTINGS_UPDATE:
    return {
      ...state,
      loadingNotificationSettingsUpdate: true,
    };
  case FETCH_DENTIST_REQUEST:
    return {
      ...state,
      dentistSearchParams: action.payload,
      userReport: '',
      reportType: '',
    };
  case FETCH_DSO_REQUEST:
    return {
      ...state,
      dsoSearchParams: action.payload,
      userReport: '',
      reportType: '',
    };
  case EDUCATION_ACTION.FETCH_REQUESTED_EDUCATIONS_SUCCESS: {
    return {
      ...state,
      requestedEducations: action.payload,
    };
  }
  case EDUCATION_ACTION.GET_USER_EDUCATION_SUCCESS: {
    return {
      ...state,
      userEducation: action.payload,
    };
  }
  case EDUCATION_ACTION.UPDATE_USER_EDUCATION_SUCCESS: {
    return {
      ...state,
      currentUser: action.payload,
    };
  }
  case FETCH_HYGIENIST_REQUEST:
    return {
      ...state,
      hygienistSearchParams: action.payload,
      userReport: '',
      reportType: '',
    };

  case USER_ACTIONS.GET_OFFICE_RECEIPTS_REQUEST:
    return {
      ...state,
      officeReceipts: {
        ...state.officeReceipts,
        receiptsPagination: {
          page: action.payload.page,
          limit: action.payload.limit,
        },
        isActiveRequest: true,
      },
    };
  case USER_ACTIONS.GET_OFFICE_RECEIPTS_SUCCESS: {
    const receipts = action.payload.response;
    const totalReceipts = action.payload.total;
    const pageReceipts = action.payload.page;
    const limitReceipts = action.payload.limit;

    const mergedReceipts = [...state.officeReceipts.receipts, ...receipts];

    return {
      ...state,
      officeReceipts: {
        ...state.officeReceipts,
        receipts: pageReceipts === 1 ? receipts : mergedReceipts,
        receiptsPagination: {
          ...state.officeReceipts.receiptsPagination,
          page: pageReceipts,
          limit: limitReceipts,
        },
        totalReceipts,
        isFinalPage:
            receipts.length === 0 ||
            receipts.length < state.officeReceipts.receiptsPagination.limit ||
            receipts.length === state.officeReceipts.totalReceipts,
        isActiveRequest: false,
      },
    };
  }
  case UPDATE_USER_LICENSE_SUCCESS: {
    const indexLic = state.currentUser.licenses.findIndex((lic) => lic.id === action.payload.id);

    if (indexLic === -1) {
      return state;
    }

    const existingLicense = state.currentUser.licenses?.some(
      ({ id, state, profession }) =>
        state === action.payload?.state &&
          profession === action.payload?.profession &&
          id !== action.payload?.id,
    );

    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        licenses: [
          ...state.currentUser.licenses.slice(0, indexLic),
          action.payload,
          ...state.currentUser.licenses.slice(indexLic + 1),
        ],
      },
      showExistingLicensePopup: action.payload.status === 'verified' && existingLicense?.id,
    };
  }
  case UPDATE_NATIONAL_PROVIDER_IDENTIFIER_SUCCESS:
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        professional_info: {
          ...state.currentUser.professional_info,
          nationalProviderIdentifier: action.payload,
        },
      },
    };
  case HIDE_EXISTING_LICENSE_POPUP:
    return {
      ...state,
      showExistingLicensePopup: false,
    };
  case ADD_LICENSE_SUCCESS:
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        licenses: [...state.currentUser.licenses, action.payload],
      },
    };
  case DELETE_LICENSE_SUCCESS: {
    const indexDelLic = state.currentUser.licenses.findIndex((lic) => lic.id === action.payload);

    if (indexDelLic === -1) {
      return state;
    }

    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        licenses: [
          ...state.currentUser.licenses.slice(0, indexDelLic),
          ...state.currentUser.licenses.slice(indexDelLic + 1),
        ],
      },
    };
  }
  case GET_USER_PROFESSIONS_SUCCESS:
    return {
      ...state,
      allProfessions: action.payload,
    };
  case GET_MANAGERS_LIST_SUCCESS:
    return {
      ...state,
      ramUsers: action.payload.ramUsers,
      dsoManagers: action.payload.dsoManagers,
      dsoRAMManagers: action.payload?.dsoRamManagers?.length
        ? action.payload?.dsoRamManagers
        : [],
    };
  case GET_DSO_NAMES_SUCCESS:
    return {
      ...state,
      dsoNames: action.payload,
    };
  case GET_REFERRAL_TYPES_SUCCESS:
    return {
      ...state,
      referralTypes: action.payload,
    };
  case GET_CANCELLATION_POLICIES_SUCCESS:
    return {
      ...state,
      cancellationPolicies: action.payload,
    };
  case FETCH_PARTNERSHIP_ORGANIZATIONS_SUCCESS:
    return {
      ...state,
      partnershipOrganizations: action.payload,
    };
  case GET_ALL_METADATA_SUCCESS:
    return {
      ...state,
      allMetadata: action.payload,
    };
  case CREATE_STRIPE_ACCOUNT_SUCCESS:
  case USER_ACTIONS.UPDATE_USER_STATUS:
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        account_standing: action.payload,
      },
    };
  case USER_ACTIONS.GET_OFFICE_INVOICES_REQUEST: {
    return {
      ...state,
      officeInvoices: {
        ...state.officeInvoices,
        invoicesPagination: action.payload,
        isActiveRequest: true,
      },
    };
  }
  case USER_ACTIONS.GET_OFFICE_INVOICES_SUCCESS: {
    const { invoices, totalUnpaid, page, limit } = action.payload;
    const totalInvoices = action.payload.total;

    const mergedInvoices = [...state.officeInvoices.invoices, ...invoices];

    return {
      ...state,
      officeInvoices: {
        ...state.officeInvoices,
        isActiveRequest: false,
        invoices: page === 1 ? invoices : mergedInvoices,
        invoicesPagination: {
          ...state.officeInvoices.invoicesPagination,
          page,
          limit,
        },
        totalInvoices,
        totalUnpaidInvoices: totalUnpaid,
        isFinalPage:
            invoices.length === 0 ||
            invoices.length < state.officeInvoices.invoicesPagination.limit ||
            invoices.length === state.officeInvoices.totalInvoices,
      },
    };
  }
  case USER_ACTIONS.FETCH_PROFESSIONAL_STATS_SUCCESS:
    return {
      ...state,
      totalCompletedCount: action.payload.completed_jobs,
      totalNoShowCount: action.payload.no_show_count,
      totalCancellationCount: action.payload.total_cancellation_count,
      totalLateCancellationCount: action.payload.late_cancellation_count,
      attendancePercentage: action.payload.attendance_percentage,
      averageRating: action.payload.average_rating,
    };
  case USER_ACTIONS.FETCH_PROFESSIONAL_STATS_BY_DAY_SUCCESS:
    return {
      ...state,
      totalNoShowByDayCount: action.payload.no_show_count,
      totalCancellationByDayCount: action.payload.total_cancellation_count,
      totalLateCancellationByDayCount: action.payload.late_cancellation_count,
    };
  case JOB_ACTION.BULK_CANCEL_JOBS_SUCCESS: {
    const jobs = action.payload;
    const userJobs = state.userJobs.filter((job) => !jobs.find((j) => j.id === job.id));
    return {
      ...state,
      userJobs,
      totalJobsCount: userJobs?.length,
    };
  }
  case USER_ACTIONS.RESET_UNFINISHED_USER_LIST_SEARCH:
    return {
      ...state,
      unfinishedSearchParams: defaultState.unfinishedSearchParams,
      unfinsihedUsers: defaultState.unfinsihedUsers,
      unfinsihedUsersCount: defaultState.unfinsihedUsersCount,
    };
  case USER_ACTIONS.RESET_DSO_LIST_SEARCH:
    return {
      ...state,
      dsoSearchParams: defaultState.dsoSearchParams,
      usersDSO: defaultState.usersDSO,
      countDSO: defaultState.countDSO,
    };
  case USER_ACTIONS.RESET_DENTIST_LIST_SEARCH:
    return {
      ...state,
      dentistSearchParams: defaultState.dentistSearchParams,
      usersDNT: defaultState.usersDNT,
      countDNT: defaultState.countDNT,
    };

  case USER_ACTIONS.RESET_HYGIENIST_LIST_SEARCH:
    return {
      ...state,
      hygienistSearchParams: defaultState.hygienistSearchParams,
      usersHYG: defaultState.usersHYG,
      countHYG: defaultState.countHYG,
    };
  default:
    return state;
  }
};
