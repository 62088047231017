import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { addNewGroupToDso, editDsoGroupName } from '../../../../../../actions/dsosActions';
import { Spinner } from '../../../../../commonComponents/Spinner';
import { BorderedTable } from '../../BorderedTable/BorderedTable';
import { Header } from '../../BorderedTable/Header';
import { Item } from '../../BorderedTable/Item';
import { useDsoGroupList } from './hooks';
import { OfficeList } from '../OfficeList/OfficeList';

export const GroupsTab = () => {
  const { id: dsoId } = useParams();
  const { data, total, isLoading, handleScrollList, isInitialLoading } = useDsoGroupList(dsoId);
  const [groupId, setGroupId] = useState(null);

  return (
    <div
      className="card mt-3 ml-4 mr-4 mb-3"
      style={{
        display: 'flex',
        width: '80%',
        paddingBottom: 80,
        paddingInline: 80,
      }}
    >
      <span
        style={{
          fontSize: 24,
          marginTop: 20,
          textAlign: 'center',
        }}
      >
        Groups
      </span>

      <Header action={addNewGroupToDso} label="Group" total={total} />

      <div style={{ display: 'flex', gap: 32 }}>
        <div style={{ flex: 1 }}>
          {isInitialLoading && <Spinner />}
          {!isInitialLoading && (
            <BorderedTable
              data={data}
              emptyText="There are no groups"
              isLoading={isLoading}
              onScroll={handleScrollList}
              title="Groups"
              style={{
                marginTop: 20,
                width: '100%',
              }}
              Item={({ item }) => (
                <Item
                  editAction={editDsoGroupName}
                  isSelected={groupId === item.id}
                  item={item}
                  label="Group"
                  onClick={() => setGroupId(item.id)}
                />
              )}
            />
          )}
        </div>
        {!isInitialLoading && (
          <OfficeList
            entity="group"
            entityId={groupId}
            locale={{
              emptyText:
                groupId === null
                  ? 'Please select a group to see a list of associated regions.'
                : 'No offices are associated with this group yet.',
              title: 'Offices within Group',
            }}
          />
        )}
      </div>
    </div>
  );
};
