import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Colors } from '../../../themes/colors';
import { GeneralTab } from './components/tabs';
import { TABS } from './enums';
import { useStaffDetails } from './hooks';

export const StaffDetails = () => {
  const params = useParams();
  const {
    isLoading,
    data: staffDetails,
    handleTabSelection,
    selectedTab,
  } = useStaffDetails(params?.id);

  if (!params?.id || isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          marginTop: 200,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spinner
          animation="border"
          role="status"
          variant="info"
          style={{
            height: 30,
            width: 30,
          }}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        width: '100vw',
        height: 'calc(100vh - 40px)',
        overflowY: 'scroll',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
      className="bg-light"
    >
      <div
        className="card bg-light"
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          border: 0,
        }}
      >
        <div
          className="card-body"
          style={{
            backgroundColor: '#435963',
            width: '100%',
            minHeight: '125px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: '90%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <h2
                className="card-title"
                style={{
                  position: 'relative',
                  margin: 0,
                  color: 'white',
                  fontWeight: 400,
                }}
              >
                {staffDetails.firstName} {staffDetails.lastName}
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: Colors.neutral_70,
          margin: 0,
          flex: 1,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              marginTop: '10px',
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {Object.values(TABS).map((tab) => (
              <button
                type="button"
                className="btn btn-info mr-1 ml-1"
                style={{
                  backgroundColor: selectedTab === tab ? Colors.secondary_500 : Colors.primary_500,
                  border: 'none',
                }}
                onClick={() => handleTabSelection(tab)}
              >
                {tab}
              </button>
            ))}
          </div>

          <div
            style={{
              position: 'relative',
              width: '100vw',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: 100,
            }}
          >
            {selectedTab === TABS.GENERAL && <GeneralTab />}
          </div>
        </div>
      </div>
    </div>
  );
};
