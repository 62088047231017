import tempMee from '../apis/tempMee';
import { SHOW_MESSAGE, STAFF_ACTIONS } from './actionTypes';
import { getAuthHeaders } from './authActions';

export const fetchStaffById = (staffId) => async (dispatch, getState) => {
  try {
    dispatch({ type: STAFF_ACTIONS.FETCH_STAFF_BY_ID_REQUEST });
    const headers = await getAuthHeaders();
    const { access_token: accessToken } = getState().auth;
    const response = await tempMee.get(`/auth-service/admin/office-staffs/${staffId}`, {
      headers: {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
      },
    });
    dispatch({
      type: STAFF_ACTIONS.FETCH_STAFF_BY_ID_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({ type: STAFF_ACTIONS.FETCH_STAFF_BY_ID_FAILURE, payload: err });
  }
};

export const fetchOfficeAccessesByStaffId =
  (staffId, filters = {}) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_ACTIONS.FETCH_STAFF_OFFICE_ACCESSES_REQUEST });
      const headers = await getAuthHeaders();
      const { access_token: accessToken } = getState().auth;
      const { filters: stateFilters } = getState().staff.officeAccesses;

      const response = await tempMee.get(`/auth-service/admin/office-staffs/${staffId}/accesses`, {
        params: {
          page: filters.page || stateFilters.page,
          limit: filters.limit || stateFilters.limit,
        },
        headers: {
          ...headers,
          Authorization: `Bearer ${accessToken}`,
        },
      });
      dispatch({
        type: STAFF_ACTIONS.FETCH_STAFF_OFFICE_ACCESSES_SUCCESS,
        payload: { staffId, ...response.data },
      });
    } catch (err) {
      dispatch({ type: STAFF_ACTIONS.FETCH_STAFF_OFFICE_ACCESSES_FAILURE, payload: err });
    }
  };

export const addAccessToStaffEntity = (staffId, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: STAFF_ACTIONS.ADD_ACCESS_TO_STAFF_ENTITY_REQUEST });
    const headers = await getAuthHeaders();
    const { access_token: accessToken } = getState().auth;

    const response = await tempMee.post(
      `/auth-service/admin/office-staffs/${staffId}/accesses`,
      {
        entityID: data.id,
        entityType: data.entityType,
        // TODO: ignore role id for now
        roleID: '01930a67-a5da-7ccb-9449-110a07c059fa',
      },
      {
        headers: {
          ...headers,
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    dispatch({
      type: STAFF_ACTIONS.ADD_ACCESS_TO_STAFF_ENTITY_SUCCESS,
      payload: response.data,
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'Permission was updated successfully.' },
    });
  } catch (err) {
    dispatch({ type: STAFF_ACTIONS.ADD_ACCESS_TO_STAFF_ENTITY_FAILURE, payload: err });
  }
};

export const deleteAccessOfStaffEntity = (staffId, accessId) => async (dispatch, getState) => {
  try {
    dispatch({ type: STAFF_ACTIONS.DELETE_ACCESS_TO_STAFF_ENTITY_REQUEST });
    const headers = await getAuthHeaders();
    const { access_token: accessToken } = getState().auth;

    await tempMee.delete(
      `/auth-service/admin/office-staffs/${staffId}/accesses/${accessId}`,
      {
        headers: {
          ...headers,
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    dispatch({
      type: STAFF_ACTIONS.DELETE_ACCESS_TO_STAFF_ENTITY_SUCCESS,
      payload: { accessId } ,
    });
    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'Permission was deleted successfully.' },
    });
  } catch (err) {
    dispatch({ type: STAFF_ACTIONS.DELETE_ACCESS_TO_STAFF_ENTITY_FAILURE, payload: err });
  }
};
