import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-s-alert/dist/s-alert-default.css';
import { getUsersPPJobs } from '../../actions/ppJobsAction';
import { createLoadingSelector } from '../../apis/selectors';
import { Table } from './Table/Table';
import { customColumnPPJobs, ppJobsConditionalRowStyles } from './jobs-column';
import { TabViewContainer } from '../mainComponents/User/TabViewContainer';

const ROWS_PER_PAGE = 15;

const actions = ['FETCH_USER_PP_JOBS'];
const loadingSelector = createLoadingSelector(actions);

export const UserPPJobList = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => loadingSelector(state));
  const userPPJobs = useSelector((state) => state.users.userPPJobs);
  const searchParamsPPJobs = useSelector((state) => state.users.searchParamsPPJobs);
  const totalPPJobsCount = useSelector((state) => state.users.totalPPJobsCount);
  const totalPages = Math.ceil(totalPPJobsCount / ROWS_PER_PAGE);
  const [filter, setFilter] = useState({
    page: searchParamsPPJobs?.page || 1,
    sortField: searchParamsPPJobs?.sortField || 'createdAt',
    sortOrder: searchParamsPPJobs?.sortOrder || 'desc',
  });

  const getJobUrl = (job) => `/pp-job/${job.id}`;

  const getColumn = () => [
    ...customColumnPPJobs({
      getJobUrl,
    }),
  ];

  const handleTableChange = (page) => {
    dispatch(
      getUsersPPJobs({
        ...filter,
        page,
        sortField: 'createdAt',
        sortOrder: 'desc',
      }),
    );
  };

  const handleOnSort = (selectedColumn, sortDirection) => {
    const sortObj = {
      page: filter.page,
      sortField: selectedColumn.sortField,
      sortOrder: sortDirection,
    };
    setFilter((prevState) => ({
      ...prevState,
      ...sortObj,
    }));
  };

  useEffect(() => {
    dispatch(
      getUsersPPJobs({
        ...filter,
      }),
    );
  }, [filter, dispatch]);

  return (
    <TabViewContainer
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '80%',
      }}
      title="Permanent Hires"
      variant="card"
    >
      <Table
        keyField="userPPJobList.id"
        columns={getColumn()}
        data={userPPJobs}
        noDataComponent="There are no PP jobs"
        isLoading={isLoading}
        forceLoading
        totalPages={totalPages}
        paginationPerPage={ROWS_PER_PAGE}
        paginationTotalRows={totalPPJobsCount}
        onPageChange={handleTableChange}
        onSort={handleOnSort}
        conditionalRowStyles={ppJobsConditionalRowStyles}
      />
    </TabViewContainer>
  );
};
