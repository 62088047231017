import Spinner from 'react-bootstrap/Spinner';
import { useParams } from 'react-router-dom';
import { ENTITY_TYPES } from '../../../enums';
import { LineInfo } from '../../LineInfo';
import { InfoGrid } from './components/InfoGrid';
import { SectionHeader } from './components/SectionHeader';
import { useGeneralTab } from './hooks';

export const GeneralTab = () => {
  const params = useParams();
  const { isLoading, dataGroup, dataRegion, staffDetails, dsoGroupDetails, officeAccesses } =  
  useGeneralTab(params?.id);

  const isChecked = (id) => officeAccesses?.some((item) => item.entityID === id);

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '2fr 1fr',
        columnGap: 20,
        width: '80%',
        justifyContent: 'center',
        marginTop: 12,
      }}
    >
      <div className="card" style={{ display: 'flex', paddingBottom: 80 }}>
        <div style={{ margin: '0 80px' }}>
          {isLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 400,
              }}
            >
              <Spinner
                animation="border"
                role="status"
                variant="info"
                style={{
                  height: 40,
                  width: 40,
                }}
              />
            </div>
          ) : (
            <div style={{ width: '100%', marginTop: 20 }}>
              <SectionHeader title="General Info" />
              <LineInfo title="First Name" text={staffDetails.firstName} />
              <LineInfo title="Last Name" text={staffDetails.lastName} />
              <LineInfo title="Email" text={staffDetails.email} />

              {dataGroup.length !== 0 && dataRegion.length !== 0 && (
                <SectionHeader title="Admin Associations" />
              )}
              {dataRegion.length !== 0 && (
                <InfoGrid title="Region(s)" items={dataRegion} isChecked={isChecked} entityType={ENTITY_TYPES.REGION} />
              )}
              {dataGroup.length !== 0 && (
                <InfoGrid title="Groups" items={dataGroup} isChecked={isChecked} entityType={ENTITY_TYPES.GROUP} />
              )}
            </div>
          )}
        </div>
      </div>

      {dsoGroupDetails?.name && (
        <div className="card" style={{ height: 100 }}>
          <div
            className="card-body"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'center',
            }}
          >
            <h3 className="card-title" style={{ textAlign: 'center' }}>
              {dsoGroupDetails.name}
            </h3>
          </div>
        </div>
      )}
    </div>
  );
};
