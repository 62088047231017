import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { addNewRegionToDso, editDsoRegionName } from '../../../../../../actions/dsosActions';
import { Spinner } from '../../../../../commonComponents/Spinner';
import { AddNewButton } from '../../BorderedTable/AddNewButton';
import { BorderedTable } from '../../BorderedTable/BorderedTable';
import { Item } from '../../BorderedTable/Item';
import { useDsoRegionList } from './hooks';
import { OfficeList } from '../OfficeList/OfficeList';

export const RegionsTab = () => {
  const { id: dsoId } = useParams();
  const { data, total, isLoading, handleScrollList, isInitialLoading } = useDsoRegionList(dsoId);
  const [regionId, setRegionId] = useState(null);

  return (
    <div
      className="card mt-3 ml-4 mr-4 mb-3"
      style={{
        display: 'flex',
        width: '80%',
        paddingBottom: 80,
      }}
    >
      <span
        style={{
          fontSize: 24,
          marginBlock: 20,
          textAlign: 'center',
        }}
      >
        Regions
      </span>

      <div
        style={{
          display: 'flex',
          gap: 32,
          paddingInline: 80,
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          {isInitialLoading && <Spinner />}
          {!isInitialLoading && (
            <>
              <header style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h1 style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 0 }}>
                  Regions ({total})
                </h1>
                <AddNewButton action={addNewRegionToDso} label="Region" />
              </header>
              <BorderedTable
                emptyText="There are no regions"
                data={data}
                onScroll={handleScrollList}
                isLoading={isLoading}
                Item={({ item }) => (
                  <Item
                    isSelected={regionId === item.id}
                    editAction={editDsoRegionName}
                    label="Region"
                    item={item}
                    onClick={() => setRegionId(item.id)}
                  />
                )}
                style={{
                  marginTop: 8,
                  width: '100%',
                }}
              />
            </>
          )}
        </div>
        {!isInitialLoading && (
          <OfficeList
            entity="region"
            entityId={regionId}
            locale={{
              emptyText:
                regionId === null
                  ? 'Please select a region to see a list of associated offices.'
                  : 'No offices are associated with this region yet.',
              title: 'Offices within Region',
            }}
          />
        )}
      </div>
    </div>
  );
};
