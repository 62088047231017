import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStaffById } from '../../../../actions/staffActions';
import { createLoadingSelector } from '../../../../apis/selectors';
import { TABS } from '../enums';

const actions = ['FETCH_STAFF_BY_ID'];
const loadingSelector = createLoadingSelector(actions);

export const useStaffDetails = (staffId) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.staff.staffDetails);
  const isLoading = useSelector((state) => loadingSelector(state));
  const [selectedTab, setSelectedTab] = useState(TABS.GENERAL);

  const handleTabSelection = (tab) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    dispatch(fetchStaffById(staffId));
  }, [dispatch, staffId]);

  return {
    data,
    isLoading,
    selectedTab,
    handleTabSelection,
  };
};
