import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import history from '../../../history';
import { useHighlyRatedList } from './hooks/useHighlyRatedList';
import { TabViewContainer } from '../../mainComponents/User/TabViewContainer';

export default function HighlyRatedList() {
  const { data, isLoadingFirstPage, isLoading, handleScrollList } = useHighlyRatedList();
  const allProfessions = useSelector((state) => state.jobs.allProfessions);

  if (isLoadingFirstPage) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
        <Spinner
          animation="border"
          role="status"
          variant="info"
          style={{
            height: 30,
            width: 30,
            marginLeft: 20,
            marginTop: 10,
          }}
        />
      </div>
    );
  }

  return (
    <TabViewContainer
      className="profile-tab-item"
      style={{
        alignItems: 'center',
        width: '80%',
      }}
      title="Highly-Rated Professionals"
    >
      <div style={{ width: '100%', marginBottom: 20 }}>
        <h5 className="bold global_font">
          This page contains a list of professionals that the office&apos;s last rating was 4.2 or
          higher
        </h5>
      </div>
      <div style={{ width: '100%', maxHeight: 480, overflowY: 'auto' }} onScroll={handleScrollList}>
        <table>
          <thead>
            <tr>
              <th>Action</th>
              <th>Name</th>
              <th>Profession</th>
            </tr>
          </thead>

          <tbody>
            {data?.map(({ job, professional }) => (
              <tr>
                <td>
                  <a
                    style={{ cursor: 'pointer', color: '#3eb0ca', textDecorationLine: 'underline' }}
                    onClick={(event) => {
                      event.preventDefault();
                      history.push(`/user/hygienist/${professional?.id}`);
                    }}
                    href={`/user/hygienist/${professional?.id}`}
                  >
                    View Account
                  </a>
                </td>
                <td>{professional?.fullName}</td>
                <td>
                  {allProfessions?.find((profession) => profession?.code === job?.profession)?.name}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {isLoading && (
          <div
            style={{ display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 20 }}
          >
            <Spinner
              animation="border"
              role="status"
              variant="info"
              style={{
                height: 30,
                width: 30,
                marginLeft: 20,
                marginTop: 10,
              }}
            />
          </div>
        )}
      </div>

      {!data?.length && (
        <div
          style={{
            border: '1px solid #efefef',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            padding: '20px 0px',
          }}
        >
          <h3 className="bold global_font">
            This office does not have any highly-rated professionals
          </h3>
        </div>
      )}
    </TabViewContainer>
  );
}
