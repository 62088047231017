import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Alert from 'react-s-alert';
import { connect } from 'react-redux';
import Styles from '../themes/style.module.scss';
import Login from './mainComponents/Login';
import MapContainer from './mainComponents/MapContainer';
import { DentistList } from './mainComponents/UsersList/DentistList/DentistList';
import { DSOOfficeList } from './mainComponents/UsersList/DSOOfficeList/DSOOfficeList';
import {
  UnfinishedUserList,
} from './mainComponents/UsersList/UnfinishedUserList/UnfinishedUserList';
import { ProfessionalList } from './mainComponents/UsersList/ProfessionalList/ProfessionalList';
import { Licenses } from './mainComponents/Licenses/Licenses';
import PostPPJob from './mainComponents/Job/PP/PostPPJob';

import { JobList } from './mainComponents/JobList/JobList';

import Dashboard from './commonComponents/Dashboard';
import Profile from './mainComponents/User/Profile';
import JobDetails from './mainComponents/Job/JobDetails';
import JobCreateComponent from './mainComponents/Job/JobCreateComponent';
import SMSCampaign from './mainComponents/SMSCampaign';
import TableauBoard from './mainComponents/TableauBoard';

import history from '../history';

import 'bootstrap/dist/css/bootstrap.min.css';
import { refresh } from '../actions/authActions';
import { createLoadingSelector } from '../apis/selectors';
import PPJobDetails from './mainComponents/Job/PP/PPJobDetails';
import ChatMessages from './mainComponents/ChatMessages';
import { MultidayList } from './mainComponents/MultidayList';
import { MultidayJobs } from './mainComponents/Job/Multiday/MultidayJobs';
import PostMultiday from './mainComponents/Job/Multiday/PostMultiday';
import { ReferralList } from './mainComponents/ReferralList/ReferralList';
import SSOCallbackHandler from './mainComponents/SSOCallbackHandler';
import { growthbook, shouldUseDsoStructure } from '../growthbook';
import { PermanentJobList } from './mainComponents/PermanentJobList/PermanentJobList';
import { DsosList } from './mainComponents/UsersList/DsosList/DsosList';
import { DsoGroupDetails } from './mainComponents/DsoGroupDetails/DsoGroupDetails';
import { StaffDetails } from './mainComponents/StaffDetails/StaffDetails';

const homePath = process.env.PUBLIC_URL;
const tableauBoardGraph =
  'https://prod-useast-a.online.tableau.com/t/tempmeecom/views/DashboardData/Dashboard1/6bf5bb7a-5b04-456b-9d84-e1fb49f59477/0d859810-89b3-4506-86c6-3eeb724ffb0e';
const tableauBoardList =
  'https://prod-useast-a.online.tableau.com/t/tempmeecom/views/Dashboard3_0/Sheet36';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
    };
  }

  async componentDidMount() {
    const { refresh, adminUser } = this.props;
    if (localStorage.getItem('refresh_token')) {
      await refresh();
    }
    this.setState({ ready: true });
    if (adminUser?.id) {
      growthbook.setAttributes({
        id: adminUser?.id,
      });
    }
  }

  render() {
    const { ready } = this.state;
    const { isSignedIn, isSSOLoading } = this.props;

    if (!ready || isSSOLoading) {
      return (
        <div className={Styles.screenView}>
          <div className={Styles.ModalTransparent}>
            <div
              className="spinner-border text-info d-flex justify-content-center bg-transparent" />
          </div>
        </div>
      );
    }

    if (!isSignedIn) {
      return (
        <Router basename={homePath} history={history}>
          <Route strict path="/" component={Login} />
          <Route strict path="/auth/google" component={SSOCallbackHandler} />
          <Redirect to="/" />
          <div>
            <Alert stack={{ limit: 3 }} />
          </div>
        </Router>
      );
    }

    return (
      <Router basename={homePath} history={history}>
        <Route path="/" component={Dashboard} />
        <Switch>
          <Route path="/dentist" component={DentistList} />
          <Route path="/dso" component={DSOOfficeList} />
          <Route path="/hygienist" component={ProfessionalList} />
          <Route path="/licenses" component={Licenses} />
          <Route path="/unfinished" component={UnfinishedUserList} />
          {shouldUseDsoStructure() && <Route path="/dsos" exact component={DsosList} />}
          {shouldUseDsoStructure() && <Route path="/dsos/:id/:currentTab" component={DsoGroupDetails} />}
          {shouldUseDsoStructure() && <Route path="/staff/:id" component={StaffDetails} />}
          <Route path="/jobs" component={JobList} />
          <Route path="/pp-job/:id" component={PPJobDetails} />
          <Route path="/pp-jobs" exact component={PermanentJobList} />
          <Route path="/user/:profileType/:id/:currentTab" component={Profile} />
          <Route path="/user/:profileType/:id" component={Profile} />
          <Route path="/job/:id/:currentTab" component={JobDetails} />
          <Route path="/job/:id" component={JobDetails} />
          <Route path="/pp-post-job" component={PostPPJob} />
          <Route path="/jobcreate" component={JobCreateComponent} />
          <Route path="/sms" component={SMSCampaign} />
          <Route path="/messages" exact component={ChatMessages} />
          <Route path="/messages/:id" component={(props) => <ChatMessages {...props} />} />
          <Route path="/multiday/post" exact component={PostMultiday} />
          <Route path="/multiday/:multidayId" component={MultidayJobs} />
          <Route path="/multiday" exact component={MultidayList} />
          <Route path="/referral" component={ReferralList} />
          <Route
            path="/dash-metrics"
            exact
            component={() => <TableauBoard url={tableauBoardGraph} />}
          />
          <Route
            path="/dash-list"
            exact
            component={() => <TableauBoard url={tableauBoardList} />}
          />
          <Route path="/" exact component={MapContainer} />
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
        <div>
          <Alert stack={{ limit: 3 }} />
        </div>
      </Router>
    );
  }
}

App.protoTypes = {
  isSignedIn: PropTypes.bool,
  refresh: PropTypes.func,
};

const refreshLoadingSelector = createLoadingSelector(['AUTH_REFRESH']);
const ssoLoadingSelector = createLoadingSelector(['AUTH_SIGN_IN_GOOGLE']);
const mapStateToProps = (store) => ({
  isRefeshLoading: refreshLoadingSelector(store),
  isSSOLoading: ssoLoadingSelector(store),
  isSignedIn: !!store.auth.access_token,
  adminUser: store.users.adminUser,
});

export default connect(mapStateToProps, { refresh })(App);
