import { STAFF_ACTIONS } from '../actions/actionTypes';

const defaultState = {
  staffDetails: {
    dsoID: null,
    email: null,
    firstName: null,
    id: null,
    lastName: null,
    userID: null,
  },
  officeAccesses: {
    staffId: null,
    data: [],
    filters: {
      page: 1,
      limit: 50,
    },
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case STAFF_ACTIONS.FETCH_STAFF_BY_ID_SUCCESS: {
      return {
        ...state,
        staffDetails: action.payload,
      };
    }
    case STAFF_ACTIONS.FETCH_STAFF_OFFICE_ACCESSES_SUCCESS: {
      return {
        ...state,
        officeAccesses: {
          ...state.officeAccesses,
          staffId: action.payload.staffId,
          data: action.payload.data,
        },
      };
    }
    case STAFF_ACTIONS.ADD_ACCESS_TO_STAFF_ENTITY_SUCCESS: {
      return {
        ...state,
        officeAccesses: {
          ...state.officeAccesses,
          data: [...state.officeAccesses.data, action.payload],
        },
      };
    }
    case STAFF_ACTIONS.DELETE_ACCESS_TO_STAFF_ENTITY_SUCCESS: {
      return {
        ...state,
        officeAccesses: {
          ...state.officeAccesses,
          data: state.officeAccesses.data.filter((item) => item.id !== action.payload.accessId),
        },
      };
    }
    default:
      return state;
  }
};
