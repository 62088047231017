import { DSOS_ACTIONS } from '../actions/actionTypes';

const defaultState = {
  dsosList: {
    data: [],
    total: 0,
    isActiveRequest: false,
    filters: {
      dsoName: '',
      page: 1,
      limit: 15,
    },
  },
  dsoGroupDetails: {
    id: null,
    name: null,
    code: null,
    createdAt: null,
  },
  dsoGroupList: {
    dsoId: null,
    data: [],
    total: 0,
    isActiveRequest: false,
    isFinalPage: false,
    filters: {
      page: 1,
      limit: 15,
    },
  },
  dsoRegionList: {
    dsoId: null,
    data: [],
    total: 0,
    isActiveRequest: false,
    isFinalPage: false,
    filters: {
      page: 1,
      limit: 15,
    },
  },
  dsoOfficeAdminsList: {
    dsoId: null,
    data: [],
    total: 0,
    isActiveRequest: false,
    isFinalPage: false,
    filters: {
      page: 1,
      limit: 15,
    },
  },
  dsoRegionOfficeList: {
    data: [],
    filters: {
      limit: 15,
      page: 1,
      regionId: null,
    },
    isActiveRequest: false,
    isFinalPage: false,
    total: 0,
  },
  dsoGroupOfficeList: {
    data: [],
    filters: {
      limit: 15,
      page: 1,
      regionId: null,
    },
    isActiveRequest: false,
    isFinalPage: false,
    total: 0,
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
  case DSOS_ACTIONS.FETCH_DSOS_LIST_REQUEST: {
    return {
      ...state,
      dsosList: {
        ...state.dsosList,
        isActiveRequest: true,
        filters: {
          ...state.dsosList.filters,
          ...action.payload,
        },
      },
    };
  }
  case DSOS_ACTIONS.FETCH_DSOS_LIST_SUCCESS: {
    return {
      ...state,
      dsosList: {
        ...state.dsosList,
        isActiveRequest: false,
        total: action.payload.total,
        data: action.payload.data,
        filters: {
          ...state.dsosList.filters,
          page: action.payload.page,
        },
      },
    };
  }
  case DSOS_ACTIONS.ADD_DSO_SUCCESS: {
    const {
      total,
      data,
      filters: { limit },
    } = state.dsosList;

    return {
      ...state,
      dsosList: {
        ...state.dsosList,
        data: limit - data.length > 0 ? [...data, action.payload] : data,
        total: total + 1,
      },
    };
  }
  case DSOS_ACTIONS.FETCH_DSO_GROUP_LIST_REQUEST: {
    const isSameDsoId = state.dsoGroupList.dsoId === action.payload.dsoId;
    return {
      ...state,
      dsoGroupList: {
        ...state.dsoGroupList,
        dsoId: action.payload.dsoId,
        filters: {
          ...state.dsoGroupList.filters,
          page: isSameDsoId ? action.payload.page || 1 : 1,
        },
        data: isSameDsoId ? state.dsoGroupList.data : [],
        total: isSameDsoId ? state.dsoGroupList.total : 0,
        isActiveRequest: true,
        isFinalPage: isSameDsoId ? state.dsoGroupList.isFinalPage : false,
      },
    };
  }
  case DSOS_ACTIONS.FETCH_DSO_GROUP_LIST_SUCCESS: {
    return {
      ...state,
      dsoGroupList: {
        ...state.dsoGroupList,
        isActiveRequest: false,
        isFinalPage: action.payload.data.length < state.dsoGroupList.filters.limit,
        total: action.payload.total,
        data:
            state.dsoGroupList.filters.page === 1
              ? action.payload.data
              : [...state.dsoGroupList.data, ...action.payload.data],
      },
    };
  }
  case DSOS_ACTIONS.ADD_GROUP_TO_DSO_SUCCESS: {
    const { total, data } = state.dsoGroupList;

    return {
      ...state,
      dsoGroupList: {
        ...state.dsoGroupList,
        data: data.length === total ? [...data, action.payload] : data,
        total: total + 1,
      },
    };
  }
  case DSOS_ACTIONS.EDIT_DSO_GROUP_NAME_SUCCESS: {
    return {
      ...state,
      dsoGroupList: {
        ...state.dsoGroupList,
        data: state.dsoGroupList.data.map((group) => {
          if (group.id === action.payload.id) {
            return {
              ...group,
              name: action.payload.name,
            };
          }
          return group;
        }),
      },
    };
  }
  case DSOS_ACTIONS.FETCH_DSO_REGION_LIST_REQUEST: {
    const isSameDsoId = state.dsoRegionList.dsoId === action.payload.dsoId;
    return {
      ...state,
      dsoRegionList: {
        ...state.dsoRegionList,
        dsoId: action.payload.dsoId,
        filters: {
          ...state.dsoRegionList.filters,
          page: isSameDsoId ? action.payload.page || 1 : 1,
        },
        data: isSameDsoId ? state.dsoRegionList.data : [],
        total: isSameDsoId ? state.dsoRegionList.total : 0,
        isActiveRequest: true,
        isFinalPage: isSameDsoId ? state.dsoRegionList.isFinalPage : false,
      },
    };
  }
  case DSOS_ACTIONS.FETCH_DSO_REGION_LIST_SUCCESS: {
    return {
      ...state,
      dsoRegionList: {
        ...state.dsoRegionList,
        isActiveRequest: false,
        isFinalPage: action.payload.data.length < state.dsoRegionList.filters.limit,
        total: action.payload.total,
        data:
            state.dsoRegionList.filters.page === 1
              ? action.payload.data
              : [...state.dsoRegionList.data, ...action.payload.data],
      },
    };
  }
  case DSOS_ACTIONS.ADD_REGION_TO_DSO_SUCCESS: {
    const { total, data } = state.dsoRegionList;

    return {
      ...state,
      dsoRegionList: {
        ...state.dsoRegionList,
        data: data.length === total ? [...data, action.payload] : data,
        total: total + 1,
      },
    };
  }
  case DSOS_ACTIONS.EDIT_DSO_REGION_NAME_SUCCESS: {
    return {
      ...state,
      dsoRegionList: {
        ...state.dsoRegionList,
        data: state.dsoRegionList.data.map((region) => {
          if (region.id === action.payload.id) {
            return {
              ...region,
              name: action.payload.name,
            };
          }
          return region;
        }),
      },
    };
  }
  case DSOS_ACTIONS.FETCH_DSO_BY_ID_SUCCESS: {
    return {
      ...state,
      dsoGroupDetails: action.payload,
    };
  }
  case DSOS_ACTIONS.FETCH_DSO_OFFICE_ADMINS_LIST_REQUEST: {
    const isSameDsoId = state.dsoOfficeAdminsList.dsoId === action.payload.dsoId;
    return {
      ...state,
      dsoOfficeAdminsList: {
        ...state.dsoOfficeAdminsList,
        dsoId: action.payload.dsoId,
        filters: {
          ...state.dsoOfficeAdminsList.filters,
          page: isSameDsoId ? action.payload.page || 1 : 1,
        },
        data: isSameDsoId ? state.dsoOfficeAdminsList.data : [],
        total: isSameDsoId ? state.dsoOfficeAdminsList.total : 0,
        isActiveRequest: true,
        isFinalPage: isSameDsoId ? state.dsoOfficeAdminsList.isFinalPage : false,
      },
    };
  }
  case DSOS_ACTIONS.FETCH_DSO_OFFICE_ADMINS_LIST_SUCCESS: {
    return {
      ...state,
      dsoOfficeAdminsList: {
        ...state.dsoOfficeAdminsList,
        isActiveRequest: false,
        isFinalPage: action.payload.data.length < state.dsoOfficeAdminsList.filters.limit,
        total: action.payload.total,
        data:
            state.dsoOfficeAdminsList.filters.page === 1
              ? action.payload.data
              : [...state.dsoOfficeAdminsList.data, ...action.payload.data],
        },
      };
    }
    case DSOS_ACTIONS.CLEAR_DSO_REGION_OFFICE_LIST: {
      return {
        ...state,
        dsoRegionOfficeList: defaultState.dsoRegionOfficeList,
      };
    }
    case DSOS_ACTIONS.FETCH_DSO_REGION_OFFICE_LIST_REQUEST: {
      return {
        ...state,
        dsoRegionOfficeList: {
          ...state.dsoRegionOfficeList,
          filters: {
            ...state.dsoRegionOfficeList.filters,
            ...action.payload,
          },
          isActiveRequest: true,
        },
      };
    }
    case DSOS_ACTIONS.FETCH_DSO_REGION_OFFICE_LIST_SUCCESS: {
      const { payload } = action;
      const { data, total } = payload;

      const isFirstPage = state.dsoRegionOfficeList.filters.page === 1;
      const isFinalPage =
        data.length === 0 || data.length < state.dsoRegionOfficeList.filters.limit;

      return {
        ...state,
        dsoRegionOfficeList: {
          ...state.dsoRegionOfficeList,
          data: isFirstPage
            ? data
            : [...state.dsoRegionOfficeList.data, ...data],
          isActiveRequest: false,
          isFinalPage,
          total,
        },
      };
    }
    case DSOS_ACTIONS.CLEAR_DSO_GROUP_OFFICE_LIST: {
      return {
        ...state,
        dsoGroupOfficeList: defaultState.dsoGroupOfficeList,
      };
    }
    case DSOS_ACTIONS.FETCH_DSO_GROUP_OFFICE_LIST_REQUEST: {
      return {
        ...state,
        dsoGroupOfficeList: {
          ...state.dsoGroupOfficeList,
          filters: {
            ...state.dsoGroupOfficeList.filters,
            ...action.payload,
          },
          isActiveRequest: true,
        },
      };
    }
    case DSOS_ACTIONS.FETCH_DSO_GROUP_OFFICE_LIST_SUCCESS: {
      const { payload } = action;
      const { data, total } = payload;

      const isFirstPage = state.dsoGroupOfficeList.filters.page === 1;
      const isFinalPage = data.length === 0 || data.length < state.dsoGroupOfficeList.filters.limit;

      return {
        ...state,
        dsoGroupOfficeList: {
          ...state.dsoGroupOfficeList,
          data: isFirstPage
            ? data
            : [...state.dsoGroupOfficeList.data, ...data],
          isActiveRequest: false,
          isFinalPage,
          total,
        },
      };
    }
    default:
      return state;
  }
};
